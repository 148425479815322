import React from "react";
import Carousel from "../component/Carousel";
import "./PagesCss/Contato.css";
import BotaoWhatsapp from "../component/BotaoWhatsapp";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap';
import ContatoComponent from "../component/ContatoComponent";
import CarouselComponent from "../component/Carousel";

const home1Carousel = [{ src: '/assets/Video/840x200.mp4', showControls: false }];
const home2Carousel = [{ src: '/assets/Video/540x200.mp4', showControls: false }];
const home3Carousel = [{ src: '/assets/Video/400x200.mp4', showControls: false }];

function Contato() {
  const contatoCarousel = [{ src: "/assets/Video/Contato.mp4", showControls: false }];

  return (
    
<div>      <div className="carousel-container1">
        <CarouselComponent videos={home1Carousel} />
      </div>
      <div className="carousel-container2">
        <CarouselComponent videos={home2Carousel} />
      </div>
      <div className="carousel-container3">
        <CarouselComponent videos={home3Carousel} />
      </div>

    <div className="flex-page-container">
      
      
      <div className="none">
        <Carousel videos={contatoCarousel} />
      </div>
      <div className="contact-content">
        <br /><br /><br /><br /><br /><br />
        <ContatoComponent/>
        <section id="contact">
          <div className="generic-page">
            <div className="contato-box">
              <div className="contato-row no-gutters">
                <div className="Contato-col-md-8 profile">
                  <div className="contato-row no-gutters h-100">
                    <div className="col-lg-6 col-xl-4 info">
                      <h1>São Paulo</h1>
                      <p>
                        Av. Nove de Julho, 3229, 11° andar, conj.1104<br />
                        Jardim Paulista CEP: 01407-000 - São Paulo - SP - Brasil
                      </p>
                      <p>
                        <strong>Tel:</strong> 0800 800 8002<br />
                      </p>
                      <p className="link"></p>
                      <a href="https://www.google.com/maps/place/SP+Business+Center/@-23.5682659,-46.659843,15z/data=!4m6!3m5!1s0x94ce59c53ccb80e1:0xc8551287a68f523!8m2!3d-23.5682659!4d-46.659843!16s%2Fg%2F1w0p6rvc?entry=ttu&g_ep=EgoyMDI0MTAwOC4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">Mapa de localização</a>
                      <div className="col-md-12">
                  </div>
                    </div>
                  </div>
                </div> 
               <div className="google-maps">
            <div className="map-wrap">
              <div className="map-aspect-ratio">
                <iframe
                  title="google-maps"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14628.019777974878!2d-46.659843!3d-23.5682659!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59c53ccb80e1%3A0xc8551287a68f523!2sSP%20Business%20Center!5e0!3m2!1spt-BR!2sbr!4v1728599832097!5m2!1spt-BR!2sbr"
                  className="map"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{
                    border: "0",
                    width: "400px",
                    height: "400px",
                    margin: "auto",
                    display: "block",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
              </div>
            </div>
          </div>
        </section>
        
      </div>

      <div className="contact-content">
        <section id="contact">
          <div className="generic-page">
            <div className="contato-box">
              <div className="contato-row no-gutters">
                <div className="Contato-col-md-8 profile">
                  <div className="contato-row no-gutters h-100">
                    <div className="col-lg-10 col-xl-9 info">
                      <h1>Novo Horizonte (Sede)</h1>
                      <p>
                        Rua Trajano Machado, 595<br />
                        Centro CEP: 22431-050 - Novo Horizonte - SP - Brasil
                      </p>
                      <p>
                        <strong>Tel:</strong> 0800 800 8002<br />
                      </p>
                      <p className="link"></p>
                      <a href="https://maps.app.goo.gl/5PAv1h5BnPT2BfwG9" target="_blank" rel="noopener noreferrer">Mapa de localização</a>
                    </div>
                  </div>
                </div>
                <div className="google-maps">
            <div className="map-wrap">
              <div className="map-aspect-ratio">
                <iframe
                  title="google-maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3713.003062069972!2d-49.22211382587545!3d-21.468396488050647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94be8e0dc7627061%3A0x5da7c2c63faa8979!2sR.%20Trajano%20Machado%2C%20595%20-%20centro%2C%20Novo%20Horizonte%20-%20SP%2C%2014960-060!5e0!3m2!1spt-BR!2sbr!4v1728600023547!5m2!1spt-BR!2sbr"
                  className="map"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{
                    border: "0",
                    width: "400px",
                    height: "400px",
                    margin: "auto",
                    display: "block",
                  }}
                ></iframe>
              </div>
            </div>
          </div>

              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="contact-content">
        <section id="contact">
          <div className="generic-page">
            <div className="contato-box">
              <div className="contato-row no-gutters">
                <div className="Contato-col-md-8 profile">
                  <div className="contato-row no-gutters h-100">
                    <div className="col-lg-10 col-xl-9 info">
                      <h1>São José do Rio Preto</h1>
                      <p>
                        Rua Santos Dumont, 79<br />
                        Vila Ercília, CEP: 15013-100 - São Paulo - SP - Brasil
                      </p>
                      <p>
                        <strong>Tel:</strong> 0800 800 8002<br />
                      </p>
                      <p className="link"></p>
                      <a href="https://maps.app.goo.gl/44oLcq82hQRcVzBR6" target="_blank" rel="noopener noreferrer">Mapa de localização</a>
                    </div>
                  </div>
                </div>
                <div className="google-maps">
            <div className="map-wrap">
              <div className="map-aspect-ratio">
                <iframe
                  title="google-maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3729.4304977121974!2d-49.3749339259015!3d-20.814315566452045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bdad56574855bd%3A0x4f9e75dfe7d2f07!2sR.%20Santos%20Dumont%2C%2079%20-%20Vila%20Ercilia%2C%20S%C3%A3o%20Jos%C3%A9%20do%20Rio%20Preto%20-%20SP%2C%2015013-100!5e0!3m2!1spt-BR!2sbr!4v1728600108986!5m2!1spt-BR!2sbr"
                  className="map"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{
                    border: "0",
                    width: "400px",
                    height: "400px",
                    margin: "auto",
                    display: "block",
                  }}
                ></iframe>
              </div>
            </div>
          </div>

              </div>
            </div>
          </div>
        </section>
      </div>

      <BotaoWhatsapp />
    </div>
    </div>
  );
}

export default Contato;
