import React, { useEffect, useRef } from "react";
import CarouselComponent from "../component/Carousel";
import "./PagesCss/ProcessoSeletivo.css";

function ProcessoSeletivo() {
  const home1Carousel = [{ src: '/assets/Video/840x200.mp4', showControls: false }];
  const home2Carousel = [{ src: '/assets/Video/540x200.mp4', showControls: false }];
  const home3Carousel = [{ src: '/assets/Video/vertical.mp4', showControls: false }];

  const animatedRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-slide-in');
          } else {
            entry.target.classList.remove('animate-slide-in'); // Remove a animação ao sair da vista
          }
        });
      },
      { threshold: 0.1 } // Ajusta o threshold conforme necessário
    );

    animatedRefs.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      animatedRefs.current.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <React.StrictMode>
      <div className="carousel-container1">
        <CarouselComponent videos={home1Carousel} />
      </div>
      <div className="carousel-container2">
        <CarouselComponent videos={home2Carousel} />
      </div>
      <div className="carousel-container3">
        <CarouselComponent videos={home3Carousel} />
      </div>
      
      <div className="flex-page-container">
        <section id="carreira-section" ref={el => animatedRefs.current[0] = el}>
          <h1 className="carreira-title">CARREIRA</h1>
          <p className="carreira-paragraph">
            Acreditamos que a diversidade de perspectivas é o que nos diferencia na criação de soluções jurídicas inovadoras. Nossa cultura colaborativa, fundamentada em integridade, responsabilidade social e respeito à diversidade, nos torna um parceiro estratégico para nossos clientes. Valorizamos a combinação de conhecimento técnico aprofundado com uma visão estratégica e multidisciplinar. Com um olhar voltado para o futuro, nos mantemos pioneiros, inovando em processos e tecnologias, desenvolvendo talentos e inspirando os profissionais de amanhã.
          </p>
        </section>

        <section id="colunas-carreira">
          <div className="colunas-container">
            <div className="coluna" ref={el => animatedRefs.current[1] = el}>
              <h2 className="coluna-title">Jurídico</h2>
              <p className="coluna-paragraph">
                Estamos dedicados a fornecer as melhores soluções aos nossos clientes, com agilidade e eficiência. Contamos com uma equipe de sócios e advogados especializados em diversas áreas do Direito. Valorizamos a trajetória de cada colaborador e promovemos um ambiente que oferece oportunidades de crescimento.
              </p>
            </div>
            <div className="coluna" ref={el => animatedRefs.current[2] = el}>
              <h2 className="coluna-title">Administrativo</h2>
              <p className="coluna-paragraph">
                Nossa equipe administrativa é organizada em diferentes diretorias: Administrativa, Financeira, Negócios e Tecnologia, e Marketing e Desenvolvimento de Negócios. Em nossa cultura colaborativa, valorizamos profissionais que demonstram iniciativa, disposição para aprender e desejo de se desenvolver.
              </p>
            </div>
          </div>
          <div className="button-container">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdMQLkc0W06x9uC9kJeEiv4CWH6yVGJ-DBFk_eMHfGgWALM0Q/viewform" className="trabalhe-custom1-btn">Faça Parte</a>
          </div>
        </section>
      </div>
    </React.StrictMode>
  );
}

export default ProcessoSeletivo;
