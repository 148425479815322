import React from 'react';
import { Link } from 'react-router-dom';
import './PagesCss/Sequoia.css';


function sequoia() {
  return (
    <div className="margin">

<div className="areas-home-offset-md-1 areas-home-col-md-11 areas-home-box">
<br/> <br/> <br/><br/> <br/>
<div className="welcome-message">
      <h1>Bem-vindos!</h1>
      <p>
        Bem-vindos, produtores e produtoras! Damos as boas-vindas ao Marketplace sequoia, uma plataforma exclusiva para membros do Agroclub sequoia. Aqui, vocês encontrarão uma comunidade de produtores que buscam serviços e outros que estão oferecendo-os. Para localizar outros produtores em sua região, basta clicar na opção que você está procurando e após é só clicar no estado desejado e você será direcionado ao campo correspondente.
      </p>
      <p>Serviços e Produtos à disposição no seu Estado</p>
      <h5><Link to="/sequoia/#estados">Clique aqui para explorar as áreas</Link></h5>
    </div>


  <h4>Estados</h4>
  <div id="estados"></div>
  <div className="areas-sequoia-list">
    <div className="areas-sequoia-row areas-sequoia-no-gutters">
      <div className="areas-sequoia-col-lg-6 box"> {/* Estados*/}
        <ul>
          <li><Link to="/sequoia/#ceara">Ceará ➤</Link></li>
          <li><Link to="/sequoia/#goias">Goiás ➤</Link></li>
          <li><Link to="/sequoia/#mato-grosso">Mato Grosso ➤</Link></li>
          <li><Link to="/sequoia/#minas-gerais">Minas Gerais ➤</Link></li>
          <li><Link to="/sequoia/#para">Pará ➤</Link></li>
          <li><Link to="/sequoia/#rio-de-janeiro">Rio de Janeiro ➤</Link></li>
          <li><Link to="/sequoia/#rio-grande-do-sul">Rio Grande do Sul ➤</Link></li>
          <li><Link to="/sequoia/#rondonia">Rondônia ➤</Link></li>
          <li><Link to="/sequoia/#santa-catarina">Santa Catarina ➤</Link></li>
          <li><Link to="/sequoia/#sao-paulo">São Paulo ➤</Link></li>
          <li><Link to="/sequoia/#tocantins">Tocantins ➤</Link></li>
        </ul>
      </div>
    </div>
  </div>
</div>


      {/* Seção Ceará */}
      <div id="ceara"></div>
  <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Ceará</h1>
        <p><br />
          ● Produtor: Cleriston Lourenço Magalhães<br/>
          Cidade: Guaiába - CE<br/>
          Produto/Serviço: Gado para corte<br/>
          Telefone: +55 85 96921-450<br/>
          <a href="https://api.whatsapp.com/send?phone=558596921450" target="_blank" rel="noopener noreferrer">Clique aqui</a>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

      {/* Seção Goiás */}
      <div id="goias"></div>
  <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Goiás</h1>
        <p><br />
          ● Produtor: Gilmar Marques de Oliveira<br/>
          Cidade: Doverlandia - GO<br/>
          Produto/Serviço: Terra planagem<br/>
          Telefone: +55 64 84510-228<br/>
          <a href="https://api.whatsapp.com/send?phone=556484510228" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Wanderson Eder Sasdelli<br/>
          Cidade: Urutai - GO<br/>
          Produto/Serviço: Uva, Goiaba, Abóbora, Melão<br/>
          Telefone: +55 64 81555-793<br/>
          <a href="https://api.whatsapp.com/send?phone=556481555793" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Romi Batista de Menezes<br/>
          Cidade: Jandaia - GO<br/>
          Produto/Serviço: Produz e vende tijolo ecológico<br/>
          Telefone: +55 62 98125-5172<br/>
          <a href="https://api.whatsapp.com/send?phone=5562981255172"target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtora: Eliana de Araújo Leandro Lacerda<br/>
          Cidade: Edéia - GO<br/>
          Produto/Serviço: Peixes de cultivo, tilápia<br/>
          Telefone: +55 64 99964 1766<br/>
          <a href="https://api.whatsapp.com/send?phone=5564999641766" target="_blank" rel="noopener noreferrer"> Clique aqui</a>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

            {/* Seção Mato Grosso */}
      <div id="mato-grosso"></div>
  <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Mato Grosso</h1>
        <p><br />
          ● Produtor: Adriano Manoel de Almeida Martins<br/>
          Cidade: Rosário Oeste - MT<br/>
          Produto/Serviço: Prestação de serviços de colheita ou somente do maquinário de colheita<br/>
          Telefone: +55 64 99916-2626<br/>
          <a href="https://api.whatsapp.com/send?phone=556499162626" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: André Luis Fontes<br/>
          Cidade: Guiratinga - MT<br/>
          Produto/Serviço: Corretor de imóveis e terras<br/>
          Telefone: +55 66 96160-343<br/>
          <a href="https://api.whatsapp.com/send?phone=556696160343" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Antonio Carlos Ribeiro<br/>
          Cidade: Paranatinga - MT<br/>
          Produto/Serviço: Queijos, Salame, venda de gado daqui 2 anos, ovos caipiras<br/>
          Telefone: +55 66 99781-508<br/>
          <a href="https://api.whatsapp.com/send?phone=556699781508" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtora: Sabrina Rezende Prado Franco Oliveira<br/>
          Cidade: Aripuanã - MT<br/>
          Produto/Serviço: <br/>
          Telefone: +55 66 81500-807<br/>
          <a href="https://api.whatsapp.com/send?phone=556681500807" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Ricardo Furlanetto Bachiega<br/>
          Cidade: Guarantã do Norte - MT<br/>
          Produto/Serviço: Vende leite in natura<br/>
          Telefone: +55 66 99910-4321<br/>
          <a href="https://api.whatsapp.com/send?phone=5566999104321" target="_blank" rel="noopener noreferrer">Clique aqui</a>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

      {/* Seção Minas Gerais */}
      <div id="minas-gerais"></div>
      <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Minas Gerais</h1>
        <p><br />
        ● Produtor: José Rogério Vilela<br/>
    Cidade: Carmo do Rio Claro - MG<br/>
    Produto/Serviço: Venda de café<br/>
    Telefone: +55 35 99097-940<br/>
    <a href="https://api.whatsapp.com/send?phone=553599097940" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

● Produtor: João Luis Ribeiro<br/>
    Cidade: Patos de Minas - MG<br/>
    Produto/Serviço: Venda de novilhas e embriões<br/>
    Telefone: +55 34 99177-700<br/>
    <a href="https://api.whatsapp.com/send?phone=553499177700" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

● Produtor: Eder Francisco Siqueira<br/>
    Cidade: Cabo Verde - MG<br/>
    Produto/Serviço: Venda de café<br/>
    Telefone: +55 35 97213-470<br/>
    <a href="https://api.whatsapp.com/send?phone=553597213470" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

● Produtor: Flavia Aparecida Avelar<br/>
    Cidade: Capetinga - MG<br/>
    Produto/Serviço: Venda de queijo, café torrado, doce, pintinho caipira<br/>
    Telefone: +55 16 99799-4393<br/>
    <a href="https://api.whatsapp.com/send?phone=5516997994393" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

      {/* Seção Pará */}
      <div id="para"></div>
  <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Pará</h1>
        <p><br />

        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

          {/* Seção Rio de Janeiro */}
          <div id="rio-de-janeiro"></div>
  <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Rio de Janeiro</h1>
        <p><br />
          ● Produtor: Heraldo de Oliveira Lameira<br/>
          Cidade: Valença - RJ<br/>
          Produto/Serviço: Venda de bezerro<br/>
          Telefone: +55 24 99904-4247<br/>
          <a href="https://api.whatsapp.com/send?phone=5524999044247" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Anderson Araújo Pereira<br/>
          Cidade: Varre - RJ<br/>
          Produto/Serviço: Café<br/>
          Telefone: +55 22 99848-9626<br/>
          <a href="https://api.whatsapp.com/send?phone=5522998489626" target="_blank" rel="noopener noreferrer">Clique aqui</a>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

      {/* Seção Rio Grande do Sul */}
      <div id="rio-grande-do-sul"></div>
      <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Rio Grande do Sul</h1>
        <p><br />
          ● Produtor: Roberto Giovani Soares Trombini<br/>
          Cidade: São Francisco de Assis - RS<br/>
          Produto/Serviço: Faz cercas e mangueiras<br/>
          Telefone: +55 62 81255-172<br/>
          <a href="https://api.whatsapp.com/send?phone=556281255172" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Cleomar Luiz Buchner<br/>
          Cidade: São Miguel das Missões - RS<br/>
          Produto/Serviço: Oficina e reforma de implementos agrícolas, venda de repelente extrato de pirolenhoso<br/>
          Telefone: +55 55 99530-732<br/>
          <a href="https://api.whatsapp.com/send?phone=555599530732" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Neuton Wasen<br/>
          Cidade: Teutônia - RS<br/>
          Produto/Serviço: Venda de silagem<br/>
          Telefone: +55 51 96690-933<br/>
          <a href="https://api.whatsapp.com/send?phone=555196690933" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Emerson Vilarinho<br/>
          Cidade: Ibirapuitã - RS<br/>
          Produto/Serviço: Ovos caipira<br/>
          Telefone: +55 49 6871-300<br/>
          <a href="https://api.whatsapp.com/send?phone=55496871300" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Leonel Capelesso<br/>
          Cidade: Viadutos - RS<br/>
          Produto/Serviço: Venda de gado<br/>
          Telefone: +55 54 99790-675<br/>
          <a href="https://api.whatsapp.com/send?phone=555499790675" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Mario Ferreira dos Passos<br/>
          Cidade: Sobradinho - RS<br/>
          Produto/Serviço: Colheita de soja e trigo<br/>
          Telefone: +55 51 98175-479<br/>
          <a href="https://api.whatsapp.com/send?phone=555198175479" target="_blank" rel="noopener noreferrer ">Clique aqui</a><br/><br/>

          ● Produtor: Neuton Wasen<br/>
          Cidade: Teutônia - RS<br/>
          Produto/Serviço: Venda de silagem<br/>
          Telefone: +55 51 96690-933<br/>
          <a href="https://api.whatsapp.com/send?phone=555196690933" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Paulo Henrique Vaz Marques<br/>
          Cidade: Butiá - RS<br/>
          Produto/Serviço: Mandioca, queijo, leitão<br/>
          Telefone: +55 51 99825-4064<br/>
          <a href="https://api.whatsapp.com/send?phone=5551998254064" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Roberto Giovani Soares Trombini<br/>
          Cidade: São Francisco de Assis - RS<br/>
          Produto/Serviço: Faz cercas e mangueiras<br/>
          Telefone: +55 62 81255-172<br/>
          <a href="https://api.whatsapp.com/send?phone=556281255172" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Roberto Mauro Kolinsk Cossettin<br/>
          Cidade: Cruz Alta - RS<br/>
          Produto/Serviço: Frete de caminhão com carreta, tem área 38 hec em Cruz Alta para vender, parte bem localizada<br/>
          Telefone: +55 55 99661-8999<br/>
          <a href="https://api.whatsapp.com/send?phone=5555996618999" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Mario Ferreira dos Passos<br/>
          Cidade: Sobradinho - RS<br/>
          Produto/Serviço: Colheita de soja e trigo<br/>
          Telefone: +55 51 98175-479<br/>
          <a href="https://api.whatsapp.com/send?phone=555198175479" target="_blank" rel="noopener noreferrer">Clique aqui</a>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

      {/* Seção Rondônia */}
      <div id="rondonia"></div>
      <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Rondônia</h1>
        <p><br />
          ● Produtor: Luciano Timm<br/>
          Cidade: Espigão do Oeste - RO<br/>
          Produto/Serviço: ---<br/>
          Procura: Veneno para pasto<br/>
          Telefone: +55 69 92977-966<br/>
          <a href="https://api.whatsapp.com/send?phone=556992977966" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Vanildo (Aline Maltarolo)<br/>
          Cidade: Presidente Médici - RO<br/>
          Produto/Serviço: Venda de gado e bezerro<br/>
          Procura: Trator e maquinário para comprar<br/>
          Telefone: +55 69 99867-495<br/>
          <a href="https://api.whatsapp.com/send?phone=556999867495" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Walter Carneiro<br/>
          Cidade: Ariquemes - RO<br/>
          Produto/Serviço: Coco, compra e venda de garrote<br/>
          Telefone: +55 69 99151-942<br/>
          <a href="https://api.whatsapp.com/send?phone=556999151942" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>

                          {/* Seção Santa Catarina */}
              <div id="santa-catarina"></div>
          <br/> <br/> <br/>
              <section className="sequoia-section">
                <h1>Santa Catarina</h1>
                <p><br />
                  ● Produtor: Jacira Marta Gonçalves Padilha<br/>
                  Cidade: Tigrinhos - SC<br/>
                  Produto/Serviço: Propriedade está à venda (12 Hec), localizada em Tigrinhos - SC<br/>
                  Telefone: +55 49 91581-101<br/>
                  <a href="https://api.whatsapp.com/send?phone=554991581101" target="_blank" rel="noopener noreferrer">Clique aqui</a>
                </p>
                <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
              </section>


            {/* Seção São Paulo */}
            <div id="sao-paulo"></div>
        <br/> <br/> <br/>
            <section className="sequoia-section">
              <h1>São Paulo</h1>
              <p><br />
                ● Produtor: Benedito Donizete Batista<br/>
                Cidade: Parapuã - SP<br/>
                Produto/Serviço: Venda de carneiro, galinha e porco<br/>
                Telefone: +55 11 99661-2530<br/>
                <a href="https://api.whatsapp.com/send?phone=5511996612530" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

                ● Produtor: Cássio Henrique Marcolino<br/>
                Cidade: General Salgado - SP<br/>
                Produto/Serviço: Venda de buchas de metro<br/>
          Telefone: +55 17 99738-5756<br/>
          <a href="https://api.whatsapp.com/send?phone=5517997385756" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: José Luís Alves<br/>
          Cidade: Pacaembu - SP<br/>
          Produto/Serviço: Bezerro para venda, tempo mínimo 7 a 8 meses de vida<br/>
          Telefone: +55 18 99818-6429<br/>
          <a href="https://api.whatsapp.com/send?phone=5518998186429" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Anderson José Roda Gonçales<br/>
          Cidade: Caiuá - SP<br/>
          Produto/Serviço: Horta, verduras<br/>
          Telefone: +55 18 99103-1359<br/>
          <a href="https://api.whatsapp.com/send?phone=5518991031359" target="_blank" rel="noopener noreferrer">Clique aqui</a>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>




      {/* Seção Tocantins */}
      <div id="tocantins"></div>
  <br/> <br/> <br/>
      <section className="sequoia-section">
        <h1>Tocantins</h1>
        <p><br />
          ● Produtor: Claudiomiro Soares<br/>
          Cidade: Palmas - TO<br/>
          Produto/Serviço: Leite<br/>
          Telefone: +55 63 99451-210<br/>
          <a href="https://api.whatsapp.com/send?phone=556399451210" target="_blank" rel="noopener noreferrer">Clique aqui</a><br/><br/>

          ● Produtor: Enio Cesar Paula da Silveira<br/>
          Cidade: Ponte Alta - TO<br/>
          Produto/Serviço: Gado para abate<br/>
          Telefone: +55 35 49679-3214<br/>
          <a href="https://api.whatsapp.com/send?phone=5535496793214" target="_blank" rel="noopener noreferrer">Clique aqui</a>
        </p>
        <br/><h5><Link to="/sequoia#">Voltar ao topo</Link></h5>
      </section>
    </div>
  );
}

export default sequoia;
