import React, { useState, useEffect, useRef } from 'react';
import './ComponenteCSS/Cadastro.css'; // Estilos customizados
import { useForm } from 'react-hook-form';

const Cadastro = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [captchaState, setCaptchaState] = useState('initial'); // 'initial', 'verifying', 'verified'
  const [message, setMessage] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Novo estado para visibilidade
  const h2Ref = useRef(null); // Referência para o elemento h2

  const handleCaptchaChange = () => {
    if (captchaState === 'initial') {
      setCaptchaState('verifying');
      setTimeout(() => {
        setIsCaptchaChecked(true);
        setCaptchaState('verified');
      }, 2000); // 2.5 seconds delay
    }
  };

  const onSubmit = async (data) => {
    if (!isCaptchaChecked) {
      alert('Por favor, marque antes de enviar o formulário.');
      return;
    }

    const formData = new FormData();
    formData.append('entry.2005620554', data.nome);       // Nome
    formData.append('entry.2038563552', data.sobrenome);  // Sobrenome
    formData.append('entry.1045781291', data.email);      // Email
    formData.append('entry.1065046570', data.empresa);    // Empresa
    formData.append('entry.1166974658', data.pais);       // País
    formData.append('entry.839337160', data.telefone1);   // Telefone

    try {
      const response = await fetch('https://docs.google.com/forms/d/e/1FAIpQLSfdugkOqe7veNfJSN32tB1K7ikkX8LsDpOC1E_z7yQ4VuB-_w/formResponse', {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      });

      if (response.ok || response.status === 0) { // response.ok não funciona com no-cors, status 0 para sucesso
        setMessage('Formulário enviado com sucesso! Entraremos em contato em breve.');
        reset();
        setIsCaptchaChecked(false);
        setCaptchaState('initial');
      } else {
        setMessage('Erro ao enviar formulário. O servidor não está disponível. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
      setMessage('Erro ao enviar formulário. O servidor não está disponível. Tente novamente mais tarde.');
    }
  };

  const handleIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setIsVisible(true); // Ativa a animação
      } else {
        setIsVisible(false); // Reseta a animação
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1 // Altere este valor conforme necessário
    });

    if (h2Ref.current) {
      observer.observe(h2Ref.current);
    }

    return () => {
      // Adicione verificação se h2Ref.current é um elemento válido
      if (h2Ref.current instanceof Element) {
        observer.unobserve(h2Ref.current);
      }
    };
  }, [h2Ref]);

  return (
    <section id="subscribeCadastro">
      <section id="Login"></section>
      <div>
        <div className="row">
          <div className="col-lg-4 text">
            <h4>
              Cadastre-se
              <img src="/assets/img/subscribe.svg" alt="Ícone de Cadastro" />
            </h4>
            <h2
              ref={h2Ref}
              className={`small2 text-uppercase ${isVisible ? 'animate' : ''}`}
            >
              Inscreva-se em nossa newsletter e{' '}
              <span className="text-secondary text-600">
                fique por dentro dos assuntos relevantes do direito
              </span>
            </h2>
          </div>
          <div className="col-lg-8">
            <form id="form-subscribeCadastro" onSubmit={handleSubmit(onSubmit)}>
              <input name="__RequestVerificationToken" type="hidden" value="wjJNoDqEOGZTSNPgrOjrVGM2jD4w_j0y1a2exH46ycS6_vEPwV91syXKzK8wIZoN1kQt6AJ1g1" />
              <div className="form-row">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="nome"
                    className={`form-control ${errors.nome ? 'is-invalid' : ''}`}
                    placeholder="Nome"
                    maxLength="30"
                    {...register('nome', { required: 'Por favor, preencha este campo' })}
                  />
                  {errors.nome && <div className="invalid-feedback">{errors.nome.message}</div>}
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    name="sobrenome"
                    className={`form-control ${errors.sobrenome ? 'is-invalid' : ''}`}
                    placeholder="Sobrenome"
                    maxLength="90"
                    {...register('sobrenome', { required: 'Por favor, preencha este campo' })}
                  />
                  {errors.sobrenome && <div className="invalid-feedback">{errors.sobrenome.message}</div>}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <input
                    type="email"
                    name="email"
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    placeholder="E-mail"
                    maxLength="80"
                    {...register('email', { required: 'E-mail inválido' })}
                  />
                  {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                </div>
                <div className="col-md-6">
                  <input
                    type="tel"
                    name="telefone1"
                    className="form-control"
                    placeholder="Telefone"
                    maxLength="20"
                    {...register('telefone1')}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6 mb-2">
                  <div className="custom-captcha" onClick={handleCaptchaChange}>
                    <div className={`captcha-checkbox ${isCaptchaChecked ? 'checked' : ''}`}>
                      {isCaptchaChecked && <span className="checkmark">✓</span>}
                    </div>
                    <div className="captcha-text">
                      {captchaState === 'initial' && 'Não sou um robô'}
                      {captchaState === 'verifying' && 'Verificando...'}
                      {captchaState === 'verified' && 'Verificado'}
                    </div>
                    <div className="captcha-icon">
                      <img className="captcha-icon" src="/assets/img/re.png" alt="Captcha Icon" />
                    </div>
                  </div>
                  {captchaState !== 'verified' && (
                    <div className="invalid-feedback d-block">Por favor, marque antes de enviar o formulário.</div>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="notice">
                    <a href="" target="_blank">Clique aqui</a> para acessar a nossa política de proteção de dados.
                  </div>
                  <button type="submit" className="btn" disabled={!isCaptchaChecked}>
                    <span>Enviar</span>
                  </button>
                </div>
              </div>
              {message && (
                <div className={`alert ${message.includes('sucesso') ? 'alert-success' : 'alert-danger'}`} role="alert">
                  {message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cadastro;
