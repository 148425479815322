import React, { useRef, useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./PagesCss/QuemSomos.css";
import { GoTriangleRight, GoTriangleLeft } from "react-icons/go";
import CarouselComponent from "../component/Carousel";


function QuemSomos() {

  const home1Carousel = [

    { 
      src: '/assets/Video/840x200.mp4', showControls: false,
    },
  ];
  const home2Carousel = [
    { 
      src: '/assets/Video/540x200.mp4', showControls: false,
    },
  ];
  const home3Carousel = [
    { 
      src: '/assets/Video/vertical.mp4', showControls: false,
    },
  ];

  const [isPlaying, setIsPlaying] = useState(true);

  // Definindo as seções com títulos e conteúdos na ordem correta
  const sections = [
    {
      title: "Nossa História",
      content: [
        "Somos Custódio Quessada Lima Advogados. Uma banca de advocacia composta por mais de duas dezenas de colaboradores que atuam simultaneamente em 19 estados brasileiros. Desde a sua fundação, a CQL|ADV sempre foi focada na renegociação de dívidas e contratos bancários. Contudo, no início, o escritório atuava exclusivamente com contratos bancários ligados ao crédito rural, área na qual a banca mantém posição de destaque até hoje.",
        "Em 2022, decidimos manter o enfoque na discussão de questões bancárias, porém com o leque mais aberto, passando a atuar também com dívidas contraídas por aposentados e servidores públicos referentes a empréstimos consignados com desconto em folha de pagamento. Assim, possuímos atualmente dois departamentos divididos de acordo com sua área de atuação especializada: um voltado exclusivamente para questões ligadas ao direito agrário e do agronegócio, e outro que se dedica à solução de problemas ligados a contratos bancários de crédito consignado. A missão de ambos é entregar paz aos clientes da CQL|ADV, por meio da proteção jurídica de seus direitos."
      ]
    },
    {
      title: "Missão",
      content: [
        "Possibilitar que os jurisdicionados conheçam seus direitos e ser a melhor chance possível para que eles consigam vê-los reconhecidos e efetivados."
      ]
    },
    {
      title: "Visão",
      content: [
        "Ser uma organização nacionalmente conhecida como uma fonte de informação jurídica confiável e um meio eficaz de acesso à justiça."
      ]
    },
    {
      title: "Valores",
      content: [
        "- Democratização da informação<br />- Be the best chance<br />- Customer centric culture<br />- Valorização humana e profissional do time de colaboradores"
      ]
    },
    {
      title: "Nossos Prêmios",
      content: [
        "Essas conquistas representam o reconhecimento de nosso compromisso em atender às necessidades de nossos clientes e garantir que seus direitos sejam sempre assegurados. Gostaríamos de expressar nossa profunda gratidão a cada um de nossos clientes, pois sem seu apoio e confiança em nossos serviços, essa conquista não seria possível. Desde o início, nossa missão tem sido oferecer serviços jurídicos excepcionais, focados em garantir ao produtor rural a garantia de seu direito!"
      ]
    }
  ];

  // Criando referências fora do mapeamento
  const refs = useRef(sections.map(() => React.createRef()));
  const [visibility, setVisibility] = useState(Array(sections.length).fill(false));

  useEffect(() => {
    const observers = refs.current.map((ref, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setVisibility((prev) => {
                const newVisibility = [...prev];
                newVisibility[index] = true;
                return newVisibility;
              });
            } else {
              setVisibility((prev) => {
                const newVisibility = [...prev];
                newVisibility[index] = false; // Reseta a visibilidade quando sai do viewport
                return newVisibility;
              });
            }
          });
        },
        { threshold: 0.2 }
      );

      if (ref.current) observer.observe(ref.current);

      return observer;
    });

    return () => {
      observers.forEach((observer, index) => {
        if (refs.current[index].current) observer.unobserve(refs.current[index].current);
      });
    };
  }, []);

  const QuemSomosCarousel = [{ src: '/assets/Video/DamaJustica.mp4', showControls: false }];
  const QuemSomosCarouselFotos = [
    { src: '/assets/img/Sede.jpg' },
    { src: '/assets/img/Sede1.jpg' },
    { src: '/assets/img/Sede2.jpg' },
    { src: '/assets/img/Sede3.jpg' },
    { src: '/assets/img/Sede4.jpg' },
    { src: '/assets/img/Sede5.jpg' },
    { src: '/assets/img/Sede6.jpg' },
    { src: '/assets/img/Sede7.jpg' },
    { src: '/assets/img/Sede8.jpg' },
    { src: '/assets/img/Sede9.jpg' }
  ];

  return (
    <div>
                  <div className="carousel-container1">
        <CarouselComponent videos={home1Carousel} />
        </div>
        <div className="carousel-container2">
        <CarouselComponent videos={home2Carousel} />
        </div>
        <div className="carousel-container3">
        <CarouselComponent videos={home3Carousel} />
        </div>

    <div className="flex-page-container"><br/>
      <div className="magin-bottom">
      </div>
      <section id="page-content">
        <section id="presentation">
          <div className="div-small">
            <div><br/><br/><br/><br/><br/><br/><br/><br/>
              <div className="quem-somos-container">
                {sections.map((section, sectionIndex) => (
                  <div key={sectionIndex} ref={refs.current[sectionIndex]}>
                    <h1 className={`h1 ${visibility[sectionIndex] ? "h1-visible" : ""}`}>{section.title}</h1>
                    {section.content.map((para, paraIndex) => (
                      <p
                        key={paraIndex}
                        className={visibility[sectionIndex] ? "p-visible" : ""}
                        dangerouslySetInnerHTML={{ __html: para }}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="image-item">
            <img src="assets/img/PremioLAQI.jpeg" alt="Imagem 1" className="custom-image" />
            <img src="assets/img/PremioTQB.jpeg" alt="Imagem 2" className="custom-image" />
          </div>
          <br/><br/><br/><br/><br/>
          <div className="div-small">
            <div className="gallery-container">
              <button className="nav-button left" onClick={() => document.querySelector('.image-gallery-left-nav').click()}><GoTriangleLeft /></button>
              <ImageGallery
                items={QuemSomosCarouselFotos.map(photo => ({ original: photo.src, thumbnail: photo.src }))}
                showFullscreenButton={true}
                showPlayButton={true}
                showThumbnails={true}
                showBullets={true}
                autoPlay={false} // Caso deseje desativar autoplay
              />
              <button className="nav-button right" onClick={() => document.querySelector('.image-gallery-right-nav').click()}><GoTriangleRight /></button>
            </div>
            <div className="margin"></div>
          </div>
        </section>
      </section>
    </div>
        </div>

  );
}

export default QuemSomos;
