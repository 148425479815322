  import React, { useState, useEffect, useRef } from 'react';
  import './ComponenteCSS/ContatoComponent.css'; // Estilos customizados
  import { useForm } from 'react-hook-form';

  const ContatoComponent = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
    const [captchaState, setCaptchaState] = useState('initial'); // 'initial', 'verifying', 'verified'
    const [message, setMessage] = useState(null);
    const [isVisible, setIsVisible] = useState(false); // Novo estado para visibilidade
    const h2Ref = useRef(null); // Referência para o elemento h2
  
    const handleCaptchaChange = () => {
      if (captchaState === 'initial') {
        setCaptchaState('verifying');
        setTimeout(() => {
          setIsCaptchaChecked(true);
          setCaptchaState('verified');
        }, 2000); // 2 segundos de delay
      }
    };
  
    const onSubmit = async (data) => {
      if (!isCaptchaChecked) {
        alert('Por favor, marque antes de enviar o formulário.');
        return;
      }
  
      const formData = new FormData();
      formData.append('entry.2005620554', data.nomeCompleto);       // Nome Completo
      formData.append('entry.1479000601', data.areasInteresse);    // Área de Interesse
      formData.append('entry.1045781291', data.email);              // E-mail
      formData.append('entry.1166974658', data.telefone);           // Telefone
      formData.append('entry.839337160', data.mensagem);            // Mensagem
  
      try {
        const response = await fetch('https://docs.google.com/forms/d/e/1FAIpQLSf7WVnzOpr1K6u9UYbyGvpddxl5v1TDMxw7MHEDZW3IhfWbZQ/formResponse', {
          method: 'POST',
          body: formData,
          mode: 'no-cors'
        });
  
        if (response.ok || response.status === 0) { // response.ok não funciona com no-cors, status 0 para sucesso
          setMessage('Formulário enviado com sucesso! Entraremos em contato em breve.');
          reset();
          setIsCaptchaChecked(false);
          setCaptchaState('initial');
        } else {
          setMessage('Erro ao enviar formulário. O servidor não está disponível. Tente novamente mais tarde.');
        }
      } catch (error) {
        console.error('Erro ao enviar formulário:', error);
        setMessage('Erro ao enviar formulário. O servidor não está disponível. Tente novamente mais tarde.');
      }
    };
  
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Ativa a animação
        } else {
          setIsVisible(false); // Reseta a animação
        }
      });
    };
  
    useEffect(() => {
      const observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.1 // Altere este valor conforme necessário
      });
  
      if (h2Ref.current) {
        observer.observe(h2Ref.current);
      }
  
      return () => {
        if (h2Ref.current) {
          observer.unobserve(h2Ref.current);
        }
      };
    }, [h2Ref]);
    

    return (
      <section id="subscribeContato">
        <section id="Login"></section>
        <div>
          <div className="row">
            <div className="col-lg-4 text">
              <h4>
                Fale conosco
                <img src="/assets/img/subscribe.svg" alt="Ícone de ContatoComponent" />
              </h4>
              <h2
                ref={h2Ref}
                className={`small text-uppercase ${isVisible ? 'animate' : ''}`}
              >
                Deixe uma mensagem{' '}
                <span className="text-secondary text-600">
                  Entraremos em contato em breve.
                </span>
              </h2>
            </div>
            <div className="col-lg-8">
              <form id="form-subscribe" onSubmit={handleSubmit(onSubmit)}>
                <input name="__RequestVerificationToken" type="hidden" value="wjJNoDqEOGZTSNPgrOjrVGM2jD4w_j0y1a2exH46ycS6_vEPwV91syXKzK8wIZoN1kQt6AJ1g1" />
                <div className="form-row">
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      name="nomeCompleto"
                      className={`form-control ${errors.nomeCompleto ? 'is-invalid' : ''}`}
                      placeholder="Nome Completo*"
                      maxLength="100"
                      {...register('nomeCompleto', { required: 'Por favor, preencha este campo' })}
                    />
                    {errors.nomeCompleto && <div className="invalid-feedback">{errors.nomeCompleto.message}</div>}
                  </div>
                  <div className="col-md-6 mb-2">
                    <select
                      name="areasInteresse"
                      className={`form-control ${errors.areasInteresse ? 'is-invalid' : ''}`}
                      {...register('areasInteresse', { required: 'Por favor, selecione uma área' })}
                    >
                      <option value="">Informe à Área de Interesse*</option>
                      <option value="AGRONEGÓCIOS">AGRONEGÓCIOS</option>
                      <option value="BANCÁRIO E TRANSAÇÕES FINANCEIRAS">BANCÁRIO E TRANSAÇÕES FINANCEIRAS</option>
                      <option value="BIOCOMBUSTÍVEIS">BIOCOMBUSTÍVEIS</option>
                      <option value="CONTENCIOSO CÍVEL">CONTENCIOSO CÍVEL</option>
                      <option value="CONTRATOS">CONTRATOS</option>
                      <option value="DIREITO PENAL FINANCEIRO">DIREITO PENAL FINANCEIRO</option>
                      <option value="ENERGIA RENOVÁVEL">ENERGIA RENOVÁVEL</option>
                      <option value="FAMÍLIA E SUCESSÕES">FAMÍLIA E SUCESSÕES</option>
                      <option value="GESTÃO PATRIMONIAL">GESTÃO PATRIMONIAL</option>
                      <option value="LITÍGIOS SOCIETÁRIOS E COMERCIAIS">LITÍGIOS SOCIETÁRIOS E COMERCIAIS</option>
                      <option value="NEGÓCIOS IMOBILIÁRIOS">NEGÓCIOS IMOBILIÁRIOS</option>
                      <option value="PREVIDENCIÁRIO">PREVIDENCIÁRIO</option>
                      <option value="PROPRIEDADE INTELECTUAL">PROPRIEDADE INTELECTUAL</option>
                      <option value="RECUPERAÇÃO TRIBUTÁRIA">RECUPERAÇÃO TRIBUTÁRIA</option>
                      <option value="REESTRUTURAÇÃO E INSOLVÊNCIA">REESTRUTURAÇÃO E INSOLVÊNCIA</option>
                      <option value="RELAÇÕES DE CONSUMO">RELAÇÕES DE CONSUMO</option>
                      <option value="RELAÇÕES GOVERNAMENTAIS">RELAÇÕES GOVERNAMENTAIS</option>
                      <option value="SAÚDE E ACESSIBILIDADE">SAÚDE E ACESSIBILIDADE</option>
                      <option value="SEGUROS E RESSEGUROS">SEGUROS E RESSEGUROS</option>
                      <option value="TECNOLOGIA">TECNOLOGIA</option>
                      <option value="TRABALHISTA">TRABALHISTA</option>
                      <option value="TRIBUTÁRIO">TRIBUTÁRIO</option>
                      <option value="VENTURE CAPITAL E STARTUPS">VENTURE CAPITAL E STARTUPS</option>
                      <option value="OUTRO">OUTRO</option>
                    </select>
                    {errors.areasInteresse && <div className="invalid-feedback">{errors.areasInteresse.message}</div>}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6 mb-2">
                    <input
                      type="email"
                      name="email"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      placeholder="E-mail*"
                      {...register('email', { required: 'Por favor, preencha este campo' })}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="tel"
                      name="telefone"
                      className={`form-control ${errors.telefone ? 'is-invalid' : ''}`}
                      placeholder="Telefone*"
                      {...register('telefone', { required: 'Por favor, preencha este campo' })}
                    />
                    {errors.telefone && <div className="invalid-feedback">{errors.telefone.message}</div>}
                  </div>
                </div>

                <div className="form-group mb-4">
                  <textarea
                    name="mensagem"
                    className={`form-control ${errors.mensagem ? 'is-invalid' : ''}`}
                    placeholder="Mensagem*"
                    {...register('mensagem', { required: 'Por favor, preencha este campo' })}
                  ></textarea>
                  {errors.mensagem && <div className="invalid-feedback">{errors.mensagem.message}</div>}
                </div>


                <div className="form-row">
                  <div className="col-md-6 mb-2">
                    <div className="custom-captcha" onClick={handleCaptchaChange}>
                      <div className={`captcha-checkbox ${isCaptchaChecked ? 'checked' : ''}`}>
                        {isCaptchaChecked && <span className="checkmark">✓</span>}
                      </div>
                      <div className="captcha-text">
                        {captchaState === 'initial' && 'Não sou um robô'}
                        {captchaState === 'verifying' && 'Verificando...'}
                        {captchaState === 'verified' && 'Verificado'}
                      </div>
                      <div className="captcha-icon">
                        <img className="captcha-icon" src="/assets/img/re.png" alt="Captcha Icon" />
                      </div>
                    </div>
                    {captchaState !== 'verified' && (
                      <div className="invalid-feedback d-block">Por favor, marque antes de enviar o formulário.</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="notice">
                      <a href="" target="_blank" rel="noopener noreferrer">Clique aqui</a> para acessar a nossa política de proteção de dados.
                    </div>
                    <button type="submit" className="btn" disabled={!isCaptchaChecked}>
                      <span>Enviar</span>
                    </button>
                  </div>
                </div>

                {message && (
                  <div className={`alert ${message.includes('sucesso') ? 'alert-success' : 'alert-danger'}`} role="alert">
                    {message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  };

  export default ContatoComponent;