import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AreasPage from './Pages/AreasPage';
import Areas from './component/Areas';
import CadastroPage from './Pages/CadastroPage';
import Footer from './component/Footer';
import ProcessoSeletivo from './Pages/ProcessoSeletivo';
import QuemSomos from './Pages/QuemSomos';
import Escritorio from './Pages/Escritorio';
import Navbar1 from './component/Navbar1';
import ProgressBar from './component/ProgressBar';
import ScrollToTop from './component/ScrollToTop';
import Contato from './Pages/Contato';
import LGPD from './Pages/LGPD';
import TermosDeUso from './Pages/TermosDeUso';
import Sequoia from './Pages/Sequoia';

function App() {
  return (
    <Router>
      <div id="google_translate_element"></div>
      <ScrollToTop />
      <ProgressBar />
      <div className="flex-container">
        <Navbar1 />
        <main className="flex-content">
          <Routes>
            <Route path="/" element={<Escritorio />} />
            <Route path="/QuemSomos" element={<QuemSomos />} />
            <Route path="/ProcessoSeletivo" element={<ProcessoSeletivo />} />
            <Route path="/Contato" element={<Contato />} />
            <Route path="/Cadastro" element={<CadastroPage />} />
            <Route path="/Areas" element={<Areas />} />
            <Route path="/AreasPage" element={<AreasPage />} />
            <Route path="/LGPD" element={<LGPD />} />
            <Route path="/TermosDeUso" element={<TermosDeUso />} />
            <Route path="/Sequoia" element={<Sequoia />} />

          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
