import React from 'react';
import "./PagesCss/Escritorio.css"
import Cadastro from '../component/Cadastro';
import AreasHome from '../component/AreasHome';
import CarouselComponent from '../component/Carousel';

function Escritorio() {
  
  const home1Carousel = [

    { 
      src: '/assets/Video/840x200.mp4', showControls: false,
    },
  ];
  const home2Carousel = [
    { 
      src: '/assets/Video/540x200.mp4', showControls: false,
    },
  ];
  const home3Carousel = [
    { 
      src: '/assets/Video/vertical.mp4', showControls: false,
    },
  ];

 return (
    <React.StrictMode>
        <div className="carousel-container1">
        <CarouselComponent videos={home1Carousel} />
        </div>
        <div className="carousel-container2">
        <CarouselComponent videos={home2Carousel} />
        </div>
        <div className="carousel-container3">
        <CarouselComponent videos={home3Carousel} />
        </div>
        <div className="div-home">
        </div>
        <AreasHome />
   <Cadastro />
    </React.StrictMode>
  );
}

export default Escritorio;